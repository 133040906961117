// 导出页面为PDF格式
import html2canvas from 'html2canvas';
import JSPDF from 'jspdf';

export default {
  install(Vue, options) {
    Vue.prototype.ExportSavePdf = function (htmlTitle, landscape) {
      // 当前时间
      let now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1 < 10 ? ('0' + (now.getMonth() + 1)) : (now.getMonth() + 1);
      let day = now.getDate() < 10 ? '0' + now.getDate() : now.getDate();
      let hours = now.getHours() < 10 ? '0' + now.getHours() : now.getHours();
      let minutes = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes();
      let currentTime = `${year}-${month}-${day}-${hours}-${minutes}`;
      // 打印的内容
      let element = document.getElementById('pdfCentent');
      html2canvas(element, {
        logging: false
      }).then(function (canvas) {
        let pdf = new JSPDF(landscape ? 'landscape' : 'portrait', 'mm', 'a4') // A4纸，纵向-p  横向-l
        let ctx = canvas.getContext('2d')
        let a4w = landscape ? 397 : 210; // landscape-横向  默认纵向
        let a4h = landscape ? 210 : 297 // A4大小，210mm x 297mm，四边各保留20mm的边距，显示区域 200x287
        let imgHeight = Math.floor(a4h * canvas.width / a4w) // 按A4显示比例换算一页图像的像素高度
        let renderedHeight = 0

        while (renderedHeight < canvas.height) {
          let page = document.createElement('canvas')
          page.width = canvas.width
          page.height = Math.min(imgHeight, canvas.height - renderedHeight)// 可能内容不足一页

          // 用getImageData剪裁指定区域，并画到前面创建的canvas对象中
          page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0)
          pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 0, 0, a4w, Math.min(a4h, a4w * page.height / page.width)) // 添加图像到页面，保留0mm边距

          renderedHeight += imgHeight
          if (renderedHeight < canvas.height) { pdf.addPage() }// 如果后面还有内容，添加一个空页
          // delete page;
        }
        pdf.save(htmlTitle + '_' + currentTime)
      })
    }
  }
}
