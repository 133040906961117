function formatTen(num) {
    return num > 9 ? num + "" : "0" + num;
};

function formatDate(date) {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var second = date.getSeconds();
    return year + "-" + formatTen(month) + "-" + formatTen(day);
};

/**
 * 返回时间 YYY-MM-DD
 * @returns 
 */
export function getMonth() {
    const end_GMT = new Date();
    const start_GMT = new Date();
    start_GMT.setTime(start_GMT.getTime() - 3600 * 1000 * 24 * 30);
    const end = formatDate(end_GMT);
    const start = formatDate(start_GMT);
    return [start, end];
}