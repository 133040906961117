import request from '@/utils/request'

/**
 * @describe 获取用户列表
 * @param areaCodeCity
 * @param areaCodeDistrict
 * @param areaCodeSubdistrict
 * @param villageCode
 * @param realName
 * @param state
 * @param type
 * @param pageNum 1
 * @param pageSize 20
 */
export function pageUser(query) {
  return request({
    url: 'user/pageUser.do',
    method: 'GET',
    params: query
  })
}


/**
 * @describe 新增用户
 * @param userName
 * @param realName
 * @param idCard
 * @param mobile
 * @param userType
 * @param regWorkerId (userType = 5 时 必填)
 * @param areaCodeCity
 * @param areaCodeDistrict
 * @param areaCodeSubdistrict
 * @param villageCode
 */
export function saveUser(query) {
  return request({
    url: 'user/saveUser.do',
    method: 'POST',
    data: query
  })
}


/**
 * @describe 修改用户
 * @param userId
 * @param 同上
 */
export function editUser(query) {
  return request({
    url: 'user/editUser.do',
    method: 'POST',
    data: query
  })
}


/**
 * @describe 获取一个用户信息
 * @param userId
 */
export function getOneUser(query) {
  return request({
    url: 'user/getOneUser.do',
    method: 'GET',
    params: query
  })
}


/**
 * @describe 删除一个用户
 * @param userId
 */
export function delUser(query) {
  return request({
    url: 'user/delUser.do',
    method: 'POST',
    data: query
  })
}


/**
 * @describe 设置用户状态
 * @param userId
 * @param state (1:正常, 2:注销)
 */
export function setStateForUser(query) {
  return request({
    url: 'user/setStateForUser.do',
    method: 'POST',
    data: query
  })
}


/**
 * @describe 修改密码
 * @param userId
 * @param oldPwd
 * @param newPwd
 */
export function changePwd(query) {
  return request({
    url: 'user/changePwd.do',
    method: 'POST',
    data: query
  })
}


/**
 * @describe 重置密码
 * @param userId
 */
export function resetPwd(query) {
  return request({
    url: 'user/resetPwd.do',
    method: 'POST',
    data: query
  })
}


/**
 * @describe 换绑监管人员
 * @param userId
 * @param regWorkerId
 */
export function changeBindRegWorker(query) {
  return request({
    url: 'user/changeBindRegWorker.do',
    method: 'POST',
    data: query
  })
}

/**
 * 分页查看会话记录 /session/pageSessionToken.do  仅admin可见    
 * @param String kssj （必 默认当天）
 * @param String jssj （必 默认当天）
 * @param String userName 用户名
 * @param String realName 实际名
 * @param Integer invalidFlag 状态 1-未失效 2-已失效 3-忽略
 * @param Integer pageNum 必
 * @param Integer pageSize 必
 */
export function pageSessionToken(query) {
  return request({
    url: "/session/pageSessionToken.do",
    method: "POST",
    data: query
  })
}

/** 
 * 验证密码有效性 /frame/mainFrameLoad.do
 */

export function mainFrameLoad() {
  return request({
    url: "/frame/mainFrameLoad.do",
    method: "POST",
  })
}


// /log/pageLoginLog.do  分页查看登录日志
/**
 * @param String areaCodeCity; 
 * @param String areaCodeDistrict;
 * @param String areaCodeSubdistrict; 
 * @param String villageCode;
 * @param String kssj;
 * @param String jssj;
 * @param String userName 用户名 
 * @param String realName 姓名 
 * @param String unitName 单位名称 
 * @param Integer pageNum
 * @param Integer pageSize
 */
export function pageLoginLog(query) {
  return request({
    url: "/log/pageLoginLog.do",
    method: "POST",
    data:query
  })
}