const Storage = window.localStorage

const StorageName = 'qrcode_pc_admin_template_storage_name'
const StorageTitle = 'qrcode_pc_admin_template_storage_title'
const StorageUrlQuery = 'qrcode_pc_admin_template_storage_urlquery'
const StorageAreaCode = 'qrcode_pc_admin_template_storage_areacode'

// 登录人员姓名
export function getStorage() { return JSON.parse(decodeURIComponent(Storage.getItem(StorageName))) }

export function setStorage(obj) { return Storage.setItem(StorageName, encodeURIComponent(JSON.stringify(obj))) }

export function removeStorage() { return Storage.removeItem(StorageName) }

// 标题
export function getTitle() { return JSON.parse(decodeURIComponent(Storage.getItem(StorageTitle))) }

export function setTitle(obj) { return Storage.setItem(StorageTitle, encodeURIComponent(JSON.stringify(obj))) }

export function removeTitle() { return Storage.removeItem(StorageTitle) }

// 参数链接
export function getUrlQuery() { return Storage.getItem(StorageUrlQuery) }

export function setUrlQuery(text) { return Storage.setItem(StorageUrlQuery, text) }

export function removeUrlQuery() { return Storage.removeItem(StorageUrlQuery) }

// 地区
export function getAreaCode() { return Storage.getItem(StorageAreaCode) }

export function setAreaCode(text) { return Storage.setItem(StorageAreaCode, text) }

export function removeAreaCode() { return Storage.removeItem(StorageAreaCode) }
