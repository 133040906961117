import Cookies from 'js-cookie'

const TokenKey = 'qrcode_pc_info_admin_template_token'
const UserName = 'qrcode_pc_info_admin_template_userName'
const UserType = 'qrcode_pc_info_admin_template_userType'
const isJGT = 'qrcode_pc_info_admin_template_isJGT' // 监管通
const industry = 'qrcode_pc_info_admin_template_industry' // 监管通

// Token
export function setToken(code) { return Cookies.set(TokenKey, code) }

export function getToken() { return Cookies.get(TokenKey) }

export function removeToken() { return Cookies.remove(TokenKey) }

// userName
export function setUserName(code) { return Cookies.set(UserName, code) }

export function getUserName() { return Cookies.get(UserName) }
// userType
export function setUserType(code) { return Cookies.set(UserType, code) }

export function getUserType() { return Cookies.get(UserType) }

// isJGT --- 监管通
export function setJGT(code) { return Cookies.set(isJGT, code) }

export function getJGT() { return Cookies.get(isJGT) }

export function removeJGT() { return Cookies.remove(isJGT) }

//industryIds ----用户的行业类别
export function setIndustry(code) { return Cookies.set(industry, code) }
export function getIndustry() { return Cookies.get(industry) }
export function removeIndustry() { return Cookies.remove(industry) }
