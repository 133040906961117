// const {loginTitle} = require('./utils/baseUrl.js')

module.exports = {
  title: process.env.VUE_APP_BASE_loginTitle,
  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true,

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false
};
