import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
import { baseUrl } from '@/utils/baseUrl'
import { getToken, removeIndustry } from '@/utils/cookie'
import { getUrlQuery } from "@/utils/storage";

// create an axios instance
const service = axios.create({
  // baseURL: baseUrl, // url = base url + request url
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

// request interceptor
// Handle timeout
// service.interceptors.request.ontimeout = function handleTimeout() {
//   reject(createError('请求超时，请稍后再试！', config, 'ECONNABORTED', request));
//   // Clean up request
// request = null;
// };
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['X-Token'] = getToken()
    }

    // POST
    // 默认为 Content-Type: application/x-www-form-urlencoded
    // 接口单独配置的为 Content-Type: application/json; charset=utf-8
    if (config.method === 'post' && !config.noTrans) {
      if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        config.transformRequest = [function (data) {
          // 将数据拼接
          let ret = '';
          for (let it in data) {
            if (data[it] === null || data[it] === undefined) {
              data[it] = '';
            }
            ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
          }
          return ret;
        }];
      } else if (config.headers['Content-Type'] === 'multipart/form-data;chartst=utf-8') {
        config.transformRequest = [function (data) {
          return data;
        }];
      }
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// 重新请求
// axios.defaults.retry = 4;
// axios.defaults.retryDelay = 1000;
//
// axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
//   var config = err.config;
//   // If config does not exist or the retry option is not set, reject
//   if(!config || !config.retry) return Promise.reject(err);
//
//   // Set the variable for keeping track of the retry count
//   config.__retryCount = config.__retryCount || 0;
//
//   // Check if we've maxed out the total number of retries
//   if(config.__retryCount >= config.retry) {
//     // Reject with the error
//     return Promise.reject(err);
//   }
//
//   // Increase the retry count
//   config.__retryCount += 1;
//
//   // Create new promise to handle exponential backoff
//   var backoff = new Promise(function(resolve) {
//     setTimeout(function() {
//       resolve();
//     }, config.retryDelay || 1);
//   });
//
//   // Return the promise in which recalls axios to retry the request
//   return backoff.then(function() {
//     return axios(config);
//   });
// });

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;
    // res.code = 40001;
    if (res.code == 40001) {
      store.dispatch("user/logout").then(() => {
        removeIndustry();
        router.push({ path: "/login" })
        Message(res.msg);
      });
    } else {
      if (res.state === "N" && res.msg) {
        let str = "Exception";
        let number = res.msg.lastIndexOf(str);
        if (number >= 0) {
          res.msg = res.msg.substring(number + str.length);
        }
        // str = "，"
        // number = res.msg.indexOf(str);
        // if (number >= 0) {
        //   res.msg = res.msg.substring(number + str.length);
        // }
      }


      if (response.status === 500) {
        Message({
          message: res.msg || 'error',
          type: 'error',
          duration: 5 * 1000
        })

        // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
        // if (res.code === 401) {
        //   // to re-login
        //   MessageBox.confirm('登录信息已过期，请重新登录。', '确认注销', {
        //     confirmButtonText: '重新登录',
        //     type: 'warning'
        //   }).then(() => {
        //     store.dispatch('user/resetToken').then(() => {
        //       location.reload()
        //     })
        //   })
        // }
        // if (res.code === 400 || res.code === 500) {
        //   // to re-login
        //   MessageBox.confirm('您已注销，可以取消以留在此页，或重新登录。', '确认注销', {
        //     confirmButtonText: '重新登录',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //   }).then(() => {
        //     store.dispatch('user/resetToken').then(() => {
        //       location.reload()
        //     })
        //   })
        // }
        return Promise.reject(res.msg || 'error')
      } else {
        return response.config.isNeedHeader ? response : res
      }
    }
  },
  error => {
    // console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
