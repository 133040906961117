import Vue from 'vue'
import Router from 'vue-router'
/* Layout */
import Layout from '@/layout'
Vue.use(Router)

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index'),
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/addEnterprise',
    name: 'AddEnterprise',
    meta: {
      title: '主体信息报表',
      icon: ''
    },
    component: () => import('@/views/enterprise/detail'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    meta: {
      icon: 'star',
    },
    redirect: '/enterprise',
    children: [{
      path: '/enterprise',
      name: 'Enterprise',
      component: () => import('@/views/enterprise/index'),
      meta: {
        title: '主体信息',
        icon: '',
      }
    }, {
      path: 'detail',
      name: 'enterprise-detail',
      hidden: true,
      component: () => import('@/views/enterprise/detail'),
      meta: {
        title: '主体信息报表',
        icon: '',
      }
    }, {
      path: 'show',
      name: 'enterprise-show',
      hidden: true,
      component: () => import('@/views/enterprise/show'),
      meta: {
        title: '主体信息',
        icon: '',
      }
    }]
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export let asyncRoutes = [
  // {
  //   path: 'external-link',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'https://panjiachen.github.io/vue-element-admin-site/#/',
  //       meta: { title: 'External Link', icon: 'link' }
  //     }
  //   ]
  // },
  // 404 page must be placed at the end !!!
  {
    path: '*',
    redirect: '/404',
    name: '404Manage',
    hidden: true,
  },
  {
    path: '/user',
    component: Layout,
    name: 'userManage',
    meta: {
      icon: 'user',
      roles: ['admin', 'supervise']
    },
    children: [{
      path: '/user/index',
      name: 'User',
      component: () => import('@/views/user/index'),
      meta: {
        title: '用户信息',
        icon: '',
        roles: ['admin', 'supervise']
      }
    }]
  },
  {
    path: '/products',
    component: Layout,
    name: 'productsManage',
    meta: {
      icon: 'component',
      roles: ['admin', 'enterprise', 'supervise']
    },
    children: [{
      path: '/products/index',
      name: 'Products',
      component: () => import('@/views/products/index'),
      meta: {
        title: '产品信息',
        icon: '',
        roles: ['admin', 'enterprise', 'supervise']
      }
    }]
  },
  {
    path: '/cert',
    component: Layout,
    name: 'certManage',
    meta: {
      icon: 'skill',
      roles: ['admin', 'enterprise', 'supervise']
    },
    children: [{
      path: '/cert/index',
      name: 'Cert',
      component: () => import('@/views/cert/index'),
      meta: {
        title: '合格证信息',
        icon: '',
        roles: ['admin', 'enterprise', 'supervise']
      }
    }]
  },
  {
    path: '/print',
    component: Layout,
    name: 'printManage',
    meta: {
      icon: 'documentation',
      roles: ['admin', 'enterprise', 'supervise']
    },
    children: [{
      path: '/print/index',
      name: 'Print',
      component: () => import('@/views/print/index'),
      meta: {
        title: '打印信息',
        icon: '',
        roles: ['admin', 'enterprise', 'supervise']
      }
    }]
  },
  {
    path: '/showPrint',
    component: Layout,
    name: 'showPrintManage',
    meta: {
      icon: 'documentation',
      roles: ['admin', 'enterprise', 'supervise']
    },
    hidden: true,
    children: [{
      path: '/showPrint/index',
      name: 'ShowPrint',
      component: () => import('@/views/showPrint/index'),
      meta: {
        title: '打印详情',
        icon: '',
        roles: ['admin', 'enterprise', 'supervise']
      }
    }]
  },
  {
    path: '/search',
    component: Layout,
    name: 'searchManage',
    meta: {
      icon: 'search',
      roles: ['admin', 'enterprise', 'supervise']
    },
    children: [{
      path: '/search/index',
      name: 'Search',
      component: () => import('@/views/search/index'),
      meta: {
        title: '查询信息',
        icon: '',
        roles: ['admin', 'enterprise', 'supervise']
      }
    }]
  },
  {
    path: '/statistic',
    component: Layout,
    name: 'statisticManage',
    meta: {
      title: '统计信息',
      icon: 'chart',
      roles: ['admin', 'supervise']
    },
    children: [{
      path: '/statistic/index',
      name: 'Statistic',
      component: () => import('@/views/statistic/index'),
      meta: {
        title: '企业数量统计',
        icon: '',
        roles: ['admin', 'supervise']
      }
    }, {
      path: '/statistic/index_certificate_num',
      name: 'CertificateNum',
      component: () => import('@/views/statistic/index_certificate_num'),
      meta: {
        title: '合格证数量统计',
        icon: '',
        roles: ['admin', 'supervise']
      }
    }, 
    // {
    //   path: '/statistic/index_check_count',
    //   name: 'Checkcount',
    //   component: () => import('@/views/statistic/index_check_count'),
    //   meta: {
    //     title: '定性检测统计',
    //     icon: '',
    //     roles: ['admin', 'supervise']
    //   }
    // }, 
    // {
    //   path: '/statistic/index_monitor',
    //   name: 'StatisticMonitor',
    //   component: () => import('@/views/statistic/index_monitor'),
    //   meta: {
    //     title: '监测数据统计',
    //     icon: '',
    //     roles: ['admin', 'supervise']
    //   }
    // }, 
    {
      path: '/statistic/index_print_num',
      name: 'PrintNum',
      component: () => import('@/views/statistic/index_print_num'),
      meta: {
        title: '打印数量统计',
        icon: '',
        roles: ['admin', 'supervise']
      }
    }, {
      path: '/statistic/index_search_num',
      name: 'SearchNum',
      component: () => import('@/views/statistic/index_search_num'),
      meta: {
        title: '查询次数统计',
        icon: '',
        roles: ['admin', 'supervise']
      }
    }, {
      path: '/statistic/index_certificate_record',
      name: 'CertificateRecord',
      component: () => import('@/views/statistic/index_certificate_record'),
      meta: {
        title: '合格证记录统计',
        icon: '',
        roles: ['admin', 'supervise']
      }
    }, {
      path: '/statistic/index_print_record',
      name: 'PrintRecord',
      component: () => import('@/views/statistic/index_print_record'),
      meta: {
        title: '打印记录统计',
        icon: '',
        roles: ['admin', 'supervise']
      }
    }, {
      path: '/statistic/index_print_num_by_ent',
      name: 'PrintNumByEnt',
      component: () => import('@/views/statistic/index_print_num_by_ent'),
      meta: {
        title: '企业打印数量统计',
        icon: '',
        roles: ['admin', 'supervise']
      }
    }, {
      path: '/statistic/index_certificate_report',
      name: 'CertificateReport',
      component: () => import('@/views/statistic/index_certificate_report'),
      meta: {
        title: '合格证统计报表',
        icon: '',
        roles: ['admin', 'supervise']
      }
    }, 
    // {
    //   path: '/statistic/index_certificate_industry',
    //   name: 'CertificateIndustry',
    //   component: () => import('@/views/statistic/index_certificate_industry'),
    //   meta: {
    //     title: '合格证⾏业统计报表',
    //     icon: '',
    //     roles: ['admin', 'supervise']
    //   }
    // }, 
    // {
    //   path: '/statistic/index_supervise_people',
    //   name: 'SupervisePeople',
    //   component: () => import('@/views/statistic/index_supervise_people'),
    //   meta: {
    //     title: '监管人员工作统计',
    //     icon: '',
    //     roles: ['admin', 'supervise']
    //   }
    // }, 
    {
      path: '/statistic/inside_reg_worker_patrol',
      name: 'RegWorkerPatrol',
      hidden: true,
      component: () => import('@/views/statistic/inside_reg_worker_patrol'),
      meta: {
        title: '巡查记录',
        icon: '',
        roles: ['admin', 'supervise']
      }
    }, {
      path: '/statistic/inside_reg_worker_sign',
      name: 'RegWorkerSign',
      hidden: true,
      component: () => import('@/views/statistic/inside_reg_worker_sign'),
      meta: {
        title: '签到信息',
        icon: '',
        roles: ['admin', 'supervise']
      }
    }, {
      path: '/statistic/inside_reg_worker_dynamic',
      name: 'RegWorkerDynamic',
      hidden: true,
      component: () => import('@/views/statistic/inside_reg_worker_dynamic'),
      meta: {
        title: '工作动态',
        icon: '',
        roles: ['admin', 'supervise']
      }
    }]
  },
  {
    path: '/area',
    component: Layout,
    name: 'areaManage',
    meta: {icon: 'tree', roles: ['admin']},
    children: [{
      path: '/area/index',
      name: 'Area',
      component: () => import('@/views/area/index'),
      meta: {
        title: '行政地区管理',
        icon: '',
        roles: ['admin']
      }
    }]
  }, {
    path: '/instrument',
    component: Layout,
    name: 'instrumentManage',
    meta: {icon: 'el-icon-box', roles: ['admin', 'device']},
    children: [{
      path: '/instrument/index',
      name: 'Instrument',
      component: () => import('@/views/instrument/index'),
      meta: {
        title: '检测仪管理',
        icon: '',
        roles: ['admin', 'device']
      }
    }, {
      path: '/instrument/list',
      name: 'InstrumentList',
      component: () => import('@/views/instrument/list'),
      hidden: true,
      meta: {
        title: '访问资源管理',
        icon: '',
        roles: ['admin', 'device']
      }
    }]
  },
]
