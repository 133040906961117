import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken, getUserName, getUserType } from '@/utils/cookie' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import { getAreaCode } from '@/utils/storage';

// console.log('This is permission.js')

NProgress.configure({
  showSpinner: false
}) // NProgress Configuration

const whiteList = ['/login', '/addEnterprise', '/sso', "/sso-sdt"] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()
  // set page title
  document.title = getPageTitle(to.meta.title)
  // determine whether the user has logged in
  // const hasToken = getToken() || true
  // const hasUserName = getUserName() || true
  const hasToken = getToken()
  const hasUserName = getUserName()
  const hasUserType = getUserType()
  const userAreaCode = getAreaCode()
  if (hasToken && hasUserName && hasUserType) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({
        path: '/'
      })
      NProgress.done()
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      if (hasRoles) {
        next()
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          const { roles } = await store.dispatch('user/getInfo')
          // generate accessible routes map based on roles
          await store.dispatch('permission/generateRoutes', { roles, userAreaCode }).then(accessRoutes => {
            router.addRoutes(accessRoutes)
          }).catch(error => {
            console.log(error)
            throw new Error(error)
          })

          // dynamically add accessible routes

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({
            ...to,
            replace: true
          })
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken');

          Message.error(error || 'Has Error')
          // next(`/login?redirect=${to.path}`)
          next(`/login`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      // next(`/login?redirect=${to.path}`)
      next(`/login`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
