// const baseUrl = 'http://192.168.1.9:8084/webapp_war_exploded/webapp/';
// const baseUrl = 'http://121.43.198.37:8081/testsdcertificate/webapp';
// const baseUrl = 'http://test.snzfnm.com/sdcertificate/webapp/';


// 江苏
// const baseUrl = 'https://hgz.jsncpaq.com/jscertificate/webapp/'; // 江苏 - 后端接口地址
// const mobileUrl = 'https://hgzapp.jsncpaq.com/#/'; // 手机扫码地址
// const baseUrl = 'https://jshgz.snzfnm.com/jscertificate/webapp/'; // 江苏 - 后端接口地址
// const baseUrl = 'https://jsdj.snzfnm.com/jscertificatesingle/webapp/'; // 江苏 - 江苏单机版 
// const mobileUrl = 'https://jshgzapp.snzfnm.com/#/'; // 手机扫码地址
// const serveId = '32001'; // 是否 - 单机版  每次打包需要修改  格式：省份编码 + 001   不是单机就清空
// const loginTitle = '食用农产品合格证系统'; // 登录标题
// const printAreaName = '江苏省'; // 地区名称
// const printAreaCode = '32'; // 地区编码
// const footName = '江苏省农业农村厅'; // 底部链接名称
// const footUrl = 'http://nynct.jiangsu.gov.cn/'; // 底部链接
// const footTechName = ''; // 底部链接名称
// const footTechUrl = ''; // 底部链接
// const qiniuLink = 'https://up.qbox.me/'; // 七牛云上传链接
// const hasCamera = false; // 高拍仪


// 山东
// // const baseUrl = 'https://sdhgz.snzfnm.com/sdcertificate/webapp/'; // 山东
// // const loginTitle = '食用农（畜）产品合格证系统';
// // const baseUrl = 'https://jnly.snzfnm.com/sdcertificateforestry/webapp';//山东单机
// const loginTitle = '食用林产品质量安全管理系统';//山东单机 名称 对应 jnly
// const serveId = '';
// const ras = false; //是否加密以强制修改密码 【'37合格证版本（不含林业局）'】
// // const baseUrl = "http://60.208.85.72:18088/sdcertificateforestrysdt/webapp";//山东 vpn 单机
// // const serveId = '37001'; // 是否 - 单机版  每次打包需要修改  格式：省份编码 + 001   不是单机就清空
// const mobileUrl = 'https://sdhgzapp.snzfnm.com/#/';
// const printAreaName = '山东省济南市';
// const printAreaCode = '37';
// const footName = '山东省农业农村厅';
// const footUrl = 'http://nync.shandong.gov.cn/';
// const footTechName = '';
// const footTechUrl = '';
// const qiniuLink = 'https://up.qbox.me/';
// const hasCamera = true;

// 安徽
// const baseUrl = 'https://ahhgz.snzfnm.com/ahcertificate/webapp/'; // 安徽
// const mobileUrl = 'https://ahhgzapp.snzfnm.com/#/';
// const serveId = ''; // 是否 - 单机版  每次打包需要修改  格式：省份编码 + 001   不是单机就清空
// const loginTitle = '安徽食用农产品合格证系统';
// const printAreaName = '安徽';
// const printAreaCode = '34';
// const footName = '安徽省农业农村厅';
// const footUrl = 'http://nync.ah.gov.cn/';
// const footTechName = '安徽农业大学';
// const footTechUrl = 'https://www.ahau.edu.cn/';
// const qiniuLink = 'https://up.qbox.me/';
// const hasCamera = false;

// 吉林
// const baseUrl = 'https://jlhgz.snzfnm.com/jlcertificate/webapp/'; // 吉林
// const mobileUrl = 'https://jlhgzapp.snzfnm.com/#/';
// const serveId = ''; // 是否 - 单机版  每次打包需要修改  格式：省份编码 + 001   不是单机就清空
// const loginTitle = '食用农产品合格证系统';
// const printAreaName = '吉林';
// const printAreaCode = '22';
// const footName = '吉林省农业农村厅';
// const footUrl = 'http://agri.jl.gov.cn/';
// const footTechName = '';
// const footTechUrl = '';
// const qiniuLink = 'https://up.qbox.me/';
// const hasCamera = false;

// 陕西
// const baseUrl = 'https://sxhgz.snzfnm.com/s3xcertificate/webapp/'; // 陕西
// const mobileUrl = 'https://sxhgzapp.snzfnm.com/#/';
// const serveId = ''; // 是否 - 单机版  每次打包需要修改  格式：省份编码 + 001   不是单机就清空
// const loginTitle = '农产品质量安全智慧监管平台';
// const printAreaName = '陕西';
// const printAreaCode = '61';
// const footName = '陕西省农业农村厅';
// const footUrl = 'http://nyt.shaanxi.gov.cn/';
// const footTechName = '';
// const footTechUrl = '';
// const qiniuLink = 'https://up.qbox.me/';
// const hasCamera = false;

// 重庆
// const baseUrl = 'https://cqhgz.snzfnm.com/cqcertificate/webapp/'; // 后端接口地址
// const mobileUrl = 'https://cqhgzapp.snzfnm.com/#/'; // 手机扫码地址
// const serveId = ''; // 是否 - 单机版  每次打包需要修改  格式：省份编码 + 001   不是单机就清空
// const loginTitle = '农产品质量智慧生产及监管平台'; // 登录标题
// const printAreaName = '重庆市'; // 地区名称
// const printAreaCode = '50'; // 地区编码
// const footName = ''; // 底部链接名称
// const footUrl = ''; // 底部链接
// const footTechName = ''; // 底部链接名称
// const footTechUrl = ''; // 底部链接
// const qiniuLink = 'https://up-z2.qiniup.com/'; // 七牛云上传链接
// const hasCamera = false; // 高拍仪

// 河北
// const baseUrl = 'https://hshgz.snzfnm.com/hebcertificate/webapp/'; // 河北
// const mobileUrl = 'https://hshgzapp.snzfnm.com/#/';
// const serveId = ''; // 是否 - 单机版  每次打包需要修改  格式：省份编码 + 001   不是单机就清空
// const loginTitle = '食用农产品合格证系统';
// const printAreaName = '河北省';
// const printAreaCode = '13';
// const footName = '河北省农业农村厅';
// const footUrl = 'http://nync.hebei.gov.cn/';
// const footTechName = '';
// const footTechUrl = '';
// const qiniuLink = 'https://up-z2.qiniup.com/';
// const hasCamera = false;

// 四川
// const baseUrl = 'https://schgz.snzfnm.com/sccertificate/webapp/'; // 河北
// const mobileUrl = 'https://schgzapp.snzfnm.com/#/';
// const serveId = ''; // 是否 - 单机版  每次打包需要修改  格式：省份编码 + 001   不是单机就清空
// const loginTitle = '食用农产品合格证系统';
// const printAreaName = '四川省';
// const printAreaCode = '51';
// const footName = '四川省农业农村厅';
// const footUrl = 'http://nynct.sc.gov.cn/';
// const footTechName = '';
// const footTechUrl = '';
// const qiniuLink = 'https://up-z2.qiniup.com/';
// const hasCamera = false;



// module.exports =
// {
//   baseUrl,
//   mobileUrl,
//   serveId,
//   loginTitle,
//   printAreaName,
//   printAreaCode,
//   footName,
//   footUrl,
//   footTechName,
//   footTechUrl,
//   qiniuLink,
//   hasCamera,
//   ras
// }

