import store from '../store'
import {getJGT} from '@/utils/cookie';

export default function getPageTitle(pageTitle) {
  let title = getJGT() ? '山东农产品质量安全监管系统' : store.state.settings.title || '';
  if (pageTitle) {
    return `${pageTitle} - ${title}`;
  }
  return `${title}`;
}
