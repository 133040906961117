<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {baseUrl} from '@/utils/baseUrl';

export default {
  name: 'App',
  created() {
    // var reg = /^(192.168.1.)/;
    // if (window.location.hostname !== 'localhost' && !reg.test(window.location.hostname)) {
    //   // 判断非本地server时 http强制转换成https
    //   var targetProtocol = 'https:';
    //   if (window.location.protocol !== targetProtocol) {
    //     window.location.href = targetProtocol + '//' + window.location.host + window.location.search;
    //   }
    // }


  }

}
</script>
