<template>
  <div>
    <el-table :data="tableData">
      <el-table-column label="#" width="80" v-if="showSort">
        <template slot-scope="scope">{{
          pageSize * (pageNum - 1) + scope.$index + 1
        }}</template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) of table_column"
        :key="'table' + index"
        :prop="item.prop"
        :label="item.label"
        align="center"
      >
        <el-table-column
          v-show="item.children"
          v-for="(it, ind) of item.children"
          :key="'table-children' + ind"
          :prop="it.prop"
          :label="it.label"
          align="center"
        >
          <el-table-column
            v-show="it.children"
            v-for="(i, n) of it.children"
            :key="'table-children-children' + n"
            :prop="i.prop"
            :label="i.label"
            align="center"
          >
          </el-table-column>
        </el-table-column>
      </el-table-column>
      <slot name="item"></slot>
      <slot name="handle"></slot>
    </el-table>
    <el-pagination
      v-if="pageShow"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      prev-text="上一页"
      next-text="下一页"
      :current-page="pageNum"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: "xtTable",
  props: {
    table_column: {
      type: Array,
      default: [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    pageNum: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 0,
    },
    pageShow: {
      type: Boolean,
      default: false,
    },
    showSort: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    },
  },
};
</script>
