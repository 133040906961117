import request from '@/utils/request'

/**
 * @describe 登录
 * @param String loginName, String password type 0
 **/
export function toLogin(query) {
  return request({
    url: 'auth/getToken.do',
    method: 'post',
    data: query
  })
}

/**
 * @describe ras 加密登录 使用该接口 需要在 utils/ras_confing 内添加白名单
 * @params string 
 * @returns 
 */
export function rasToLogin(query) {
  return request({
    url: '/auth/enc/getToken.do',
    method: 'post',
    data: query
  })
}

/**
 * @describe /auth/logout.do 注销接口
 * @param {*} query 
 * @returns 
 */
export function authlogout() {
  return request({
    url: "/auth/logout.do",
    method: "post"
  })
}

/** 失效一个会话 /session/invalidOneSessionToken.do Long sessionTokenId  仅admin可操作 该功能基于接口4列表，仅当invalidFlag=1时，显示失效按钮 
 * sessionTokenId
 */
export function invalidOneSessionToken(query) {
  return request({
    url: "/session/invalidOneSessionToken.do",
    method: "post",
    data: query
  })
}

// sso/jnstzn/ssoToken.do?token=
export function ssoToken(query) {
  return request({
    url: 'sso/jnstzn/ssoToken.do',
    method: 'post',
    data: query,
    // headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded'
    // }
  })
}

/**
 * authCode
 */
export function getUserUniqueCode(query) {
  return request({
    url: 'http://59.206.133.162/ssoproxy/webapp/main/sdt/sso/getUserUniqueCode.do',
    method: 'get',
    params: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// sso/jnstzn/ssoToken.do?token=
export function sdtSsoToken(query) {
  return request({
    url: 'sso/sdt/ssoToken.do',
    method: 'post',
    data: query,
    // headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded'
    // }
  })
}