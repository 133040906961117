import Vue from 'vue'
import Router from 'vue-router'
// import { process.env.VUE_APP_BASE_printAreaCode, process.env.VUE_APP_BASE_serveId } from '@/utils/baseUrl'
// 江苏
import { asyncRoutes as asyncRoutes_Js, constantRoutes as constantRoutes_Js } from './modules/index-js'
// 其它地区
import { asyncRoutes as asyncRoutes_Other, constantRoutes as constantRoutes_Other } from './modules/index-other'
import { asyncRoutes as asyncRoutes_jnly, constantRoutes as constantRoutes_jnly } from './modules/index-other-jnly'

Vue.use(Router)

export let addIndexAndEnterprise = null;
export let checkAreaCodeMenu = null;
export let constantRoutes = null;
export let asyncRoutes = null;


if (process.env.VUE_APP_BASE_printAreaCode === "32") { // 江苏
  constantRoutes = constantRoutes_Js;
  asyncRoutes = asyncRoutes_Js;
} else { // 其它地区
  
  // constantRoutes = process.env.VUE_APP_BASE_printAreaCode == '37' && process.env.VUE_APP_BASE_serveId == '' ? constantRoutes_Other : constantRoutes_jnly;
  // asyncRoutes = process.env.VUE_APP_BASE_printAreaCode == '37' && process.env.VUE_APP_BASE_serveId == '' ? asyncRoutes_Other : asyncRoutes_jnly;
  constantRoutes = process.env.VUE_APP_BASE_printAreaCode == '37' && process.env.VUE_APP_BASE_serveId !== '' ? constantRoutes_jnly : constantRoutes_Other;
  asyncRoutes = process.env.VUE_APP_BASE_printAreaCode == '37' && process.env.VUE_APP_BASE_serveId !== '' ? asyncRoutes_jnly : asyncRoutes_Other;
  // constantRoutes = constantRoutes_Other;
  // asyncRoutes = asyncRoutes_Other;
}

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [...constantRoutes]
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher // reset router
}

export default router
