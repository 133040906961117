import Vue from 'vue'
import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import '@/styles/index.scss' // global css
import App from './App'
import store from './store'
import router from './router'
import XtTable from "@/components/el/xt-table";
import XtDatePicker from "@/components/xt/xt-date-picker.vue"
// import 'default-passive-events' // 去掉 passive-events warning
import '@/icons' // icon
import '@/permission' // permission control
import {
  getStorage
} from '@/utils/storage'

import Axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, Axios);

import Print from '@/utils/print';
Vue.use(Print); // 打印

import htmlToPdf from '@/utils/html2pdf';
Vue.use(htmlToPdf) // 下载PDF

// 权限判断指令
import permission from '@/directive/permission/index'
Vue.directive('permission', permission)
// 权限判断函数
import checkPermission from '@/utils/permission'
Vue.prototype.$checkPermission = checkPermission;


import {
  goBackForFresh
} from './utils/index';
Vue.prototype.$goBackForFresh = goBackForFresh;
Vue.prototype.$checkUserTypeIsAuth = () => {
  const info = getStorage();
  let userType = info.userType;
  if (userType === -1 || userType === 1) { // 游客 -1;  // 企业用户 1;
    return false
  } else { // 管理员 0; // 监管用户 2;
    return true
  }
};

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
// import { mockXHR } from '../mock'

// if (process.env.NODE_ENV === 'production') {
//   mockXHR()
// }
// set ElementUI lang to EN
Vue.use(ElementUI, { locale });
Vue.component('XtTable', XtTable)
Vue.component('XtDatePicker', XtDatePicker)




import VueAMap from 'vue-amap';

Vue.prototype.$setMapKey = () => {
  const userInfo = getStorage();
  let mapKey = 'ec71d5e769b160082ad3ce0d6893b944';
  let securityJsCode = "68b9f7a2304d26dcbf5c197588782de7";
  if (userInfo.areaCodeCity == "6101") { //西安单独的 key
    mapKey = '4cdded6d3b2db32a568abcdca2e19731';
    securityJsCode = "4d1971a9e7adb766a9c40b414895265f";
  }
  Vue.use(VueAMap);
  VueAMap.initAMapApiLoader({
    key: mapKey,
    // securityJsCode: '68b9f7a2304d26dcbf5c197588782de7', 
    // key: '3a0c2707aff5fa2342759c0386b70a82',
    plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.Geolocation', 'AMap.PolyEditor', 'AMap.CircleEditor'],
    // 默认高德 sdk 版本为 1.4.4
    v: '1.4.4'
  });
  window._AMapSecurityConfig = {
    securityJsCode: securityJsCode,// 新版key需要配合安全密钥使用
  }
}



Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
