import Vue from 'vue'
import Router from 'vue-router'
/* Layout */
import Layout from '@/layout'
import emptyLayout from "@/empty-layout";
import {
  getJGT
} from '@/utils/cookie';
// import { process.env.VUE_APP_BASE_printAreaCode } from '@/utils/baseUrl';

const isJGT = !(getJGT() === '1'); // 山东监管通
const isSD = !(process.env.VUE_APP_BASE_printAreaCode === '37'); // 山东
const isCQ = !(process.env.VUE_APP_BASE_printAreaCode === '50'); // 重庆
// const isHB = !(process.env.VUE_APP_BASE_printAreaCode === '13'); // 河北
const isSX = !(process.env.VUE_APP_BASE_printAreaCode === '61'); // 陕西

Vue.use(Router)

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: () => import('@/views/redirect/index'),
  }]
},
{
  path: '/login',
  name: 'Login',
  component: () => import('@/views/login/index'),
  hidden: true
},
{
  path: "/sso",
  name: 'sso',
  component: () => import('@/views/sso/index'),
  hidden: true
},
{
  path: '/addEnterprise',
  name: 'AddEnterprise',
  meta: {
    title: '主体管理报表',
    icon: ''
  },
  component: () => import('@/views/enterprise/detail'),
  hidden: true
},
{
  path: '/404',
  component: () => import('@/views/404'),
  hidden: true
},
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export let asyncRoutes = [
  // {
  //   path: 'external-link',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'https://panjiachen.github.io/vue-element-admin-site/#/',
  //       meta: { title: 'External Link', icon: 'link' }
  //     }
  //   ]
  // },
  // 404 page must be placed at the end !!!
  {
    path: '*',
    redirect: '/404',
    name: '404Manage',
    hidden: true,
  },
  {
    path: '/home',
    component: Layout,
    redirect: '/home',
    meta: {
      icon: 'dashboard',
      roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
    },
    children: [{
      path: '/home',
      name: 'Home',
      component: () => import('@/views/dashboard/index'),
      meta: {
        title: '系统首页',
        icon: '',
        roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
      }
    }]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/enterprise/index',
    meta: {
      title: '主体管理',
      icon: 'star',
      roles: ['admin', 'enterprise', 'supervise', '6108', '37', '610802', '6103-page']
    },
    children: [{
      path: '/enterprise/index',
      name: 'Enterprise',
      component: () => import('@/views/enterprise/index'),
      meta: {
        title: '入网主体',
        icon: '',
        roles: ['admin', 'enterprise', 'supervise', '6108', '37', '610802', '6103-page']
      }
    }, {
      path: '/enterprise/detail',
      name: 'EnterpriseDetail',
      hidden: true,
      component: () => import('@/views/enterprise/detail'),
      meta: {
        title: '主体管理报表',
        icon: '',
        activeMenu: '/enterprise/index',
        roles: ['admin', 'enterprise', 'supervise', '6108', '37', '610802', '6103-page']
      }
    }, {
      path: '/enterprise/show',
      name: 'EnterpriseShow',
      hidden: true,
      component: () => import('@/views/enterprise/show'),
      meta: {
        title: '主体管理',
        icon: '',
        activeMenu: '/enterprise/index',
        roles: ['admin', 'enterprise', 'supervise', '6108', '37', '610802', '6103-page']
      }
    },
    {
      path: '/products/index',
      name: 'Products',
      component: () => import('@/views/products/index'),
      meta: {
        title: '产品管理',
        icon: '',
        roles: ['admin', 'enterprise', 'supervise', '6108', '37', '610802', '6103-page']
      }
    },
    {
      path: '/base/index',
      name: 'base-index',
      component: () => import('@/views/base/index'),
      meta: {
        title: '基地管理',
        icon: '',
        roles: ['isDeep_admin', 'isDeep_enterprise']
      }
    },
    {
      path: '/base/detail',
      name: 'base-detail',
      hidden: true,
      component: () => import('@/views/base/detail'),
      meta: {
        title: '基地管理-详情',
        icon: '',
        activeMenu: '/base/index',
        roles: ['isDeep_admin', 'isDeep_enterprise']
      }
    },
    // 南通 购方管理 其余 客户管理
    {
      path: '/customer/index',
      name: 'customer-index',
      component: () => import('@/views/customer/index'),
      // 'admin', 'enterprise', 'supervise', '6108', '37', '610802', '6103-page']
      meta: {
        title: '购方管理',
        icon: '',
        roles: ['enterprise', 'isDeep_admin', 'isDeep_enterprise']
      }
    },
    {
      path: '/customer/detail',
      name: 'customer-detail',
      hidden: true,
      component: () => import('@/views/customer/detail'),
      meta: {
        title: '购方管理-详情',
        icon: '',
        activeMenu: '/customer/index',
        roles: ['enterprise', 'isDeep_admin', 'isDeep_enterprise']
      }
    },
    ]
  },
  // {
  //   path: '/store',
  //   component: Layout,
  //   name: 'storeManage',
  //   meta: {
  //     icon: 'shopping',
  //     roles: ['admin', 'supervise', '6108', '37', '610802', 'enterprise']
  //   },
  //   children: [
  //     {
  //       path: '/store/index',
  //       name: 'Store',
  //       component: () => import('@/views/store/index'),
  //       meta: {
  //         title: '农资店',
  //         icon: '',
  //         roles: ['admin', 'supervise', '6108', '37', '610802', 'enterprise']
  //       }
  //     }, {
  //       path: '/store/detail',
  //       name: 'StoreDetail',
  //       hidden: true,
  //       component: () => import('@/views/store/detail'),
  //       meta: {
  //         title: '农资店详情',
  //         icon: '',
  //         roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page', 'enterprise']
  //       }
  //     }]
  // },
  // {
  //   path: '/green',
  //   name: 'green',
  //   component: Layout,
  //   meta: { icon: 'shopping', roles: ['admin', '6103-lse'] },
  //   children: [
  //     {
  //       path: '/green-food-certification',
  //       name: 'green-food-certification',
  //       component: () => import('@/views/green-food-certification/index.vue'),
  //       meta: { title: '绿色食品认证', roles: ['admin', '6103-lse'] }
  //     },
  //     {
  //       path: '/green-food-certification/detail',
  //       name: '/green-food-certificationDetail',
  //       hidden: true,
  //       component: () => import('@/views/green-food-certification/detail'),
  //       meta: {
  //         title: '绿色食品认证详情',
  //         icon: '',
  //         roles: ['admin', '6103-lse']
  //       }
  //     }
  //   ]
  // },
  {
    path: '/grid',
    component: Layout,
    meta: {
      title: '监管网格',
      icon: 'tree',
      roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
    },
    children: [
      //     {
      //       path: '/grid/index',
      //       name: 'Grid',
      //       hidden: !isCQ && !isSX,
      //       component: () => import('@/views/grid/index'),
      //       meta: { title: '网格配置', icon: '', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] }
      //     }, {
      //       path: '/grid/detail',
      //       name: 'GridDetail',
      //       hidden: true,
      //       component: () => import('@/views/grid/detail'),
      //       meta: { title: '网格配置详情', icon: '', activeMenu: '/grid/index', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] }
      //     },
      {
        path: '/regulator/index',
        name: 'Regulator',
        component: () => import('@/views/regulator/index'),
        meta: {
          title: '监管机构',
          icon: '',
          roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
        }
      }, {
        path: '/regulator/detail',
        name: 'RegulatorDetail',
        hidden: true,
        component: () => import('@/views/regulator/detail'),
        meta: {
          title: '监管机构详情',
          icon: '',
          activeMenu: '/regulator/index',
          roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
        }
      },
      //     {
      //       path: '/regWorker/index',
      //       name: 'regWorker',
      //       component: () => import('@/views/regWorker/index'),
      //       meta: { title: '监管人员', icon: '', roles: ['isDeep_admin', 'isDeep_supervise'] }
      //     },
      //     { // 山东监管通
      //       path: '/sign/index',
      //       name: 'Sign',
      //       // hidden: isJGT,
      //       // 山东监管通和山东合格证合并
      //       hidden: isSD,
      //       component: () => import('@/views/sign/index'),
      //       meta: { title: '签到记录', icon: '', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] }
      //     },
      //     { // 山东监管通
      //       path: '/workerDynamic/index',
      //       name: 'WorkerDynamic',
      //       // hidden: isJGT,
      //       // 山东监管通和山东合格证合并
      //       hidden: !isCQ && !isSX,
      //       component: () => import('@/views/workerDynamic/index'),
      //       meta: { title: '工作计划', icon: '', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page', 'isDeep_supervise'] }
      //     },
      //     {
      //       path: '/workerDynamic/detail',
      //       name: 'WorkerDynamicDetail',
      //       hidden: true,
      //       component: () => import('@/views/workerDynamic/detail'),
      //       meta: {
      //         title: '工作计划详情', icon: '', activeMenu: '/workerDynamic/index', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'],
      //       }
      //     },
    ]
  },
  {
    path: '/inspection',
    component: Layout,
    name: 'inspectionManage',
    meta: {
      icon: 'el-icon-tickets',
      roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
    },
    children: [{
      path: '/inspection/index',
      name: 'Inspection',
      component: () => import('@/views/inspection/index'),
      meta: {
        title: '监督巡查',
        icon: '',
        roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
      }
    }, {
      path: '/inspection/detail',
      name: 'InspectionDetail',
      hidden: true,
      component: () => import('@/views/inspection/detail'),
      meta: {
        title: '监督巡查详情',
        icon: '',
        activeMenu: '/inspection/index',
        roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'],
      }
    }]
  },
  // {
  //   path: '/sampling',
  //   component: Layout,
  //   name: 'samplingManage',
  //   meta: { icon: 'el-icon-tickets', roles: ['admin', 'supervise','6108','37','610802','6103-page'] },
  //   children: [{
  //     path: '/sampling/index',
  //     name: 'Sampling',
  //     component: () => import('@/views/sampling/index'),
  //     meta: { title: '采样任务', icon: '', roles: ['admin', 'supervise','6108','37','610802','6103-page'] }
  //   }, {
  //     path: '/sampling/detail',
  //     name: 'SamplingDetail',
  //     hidden: true,
  //     component: () => import('@/views/sampling/detail'),
  //     meta: {
  //       title: '采样任务详情', icon: '', activeMenu: '/sampling/index', roles: ['admin', 'supervise','6108','37','610802','6103-page'], }
  //   }]
  // },
  // {
  //   path: '/sample',
  //   component: Layout,
  //   redirect: '/sample/index',
  //   meta: { icon: 'el-icon-office-building', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] },
  //   children: [
  //     {
  //       path: '/sample/index',
  //       name: 'Sample',
  //       component: () => import('@/views/sample/index'),
  //       meta: { title: '采样任务', icon: '', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] }
  //     },
  //     {
  //       path: '/sample/detail',
  //       name: 'SampleDetail',
  //       hidden: true,
  //       component: () => import('@/views/sample/detail'),
  //       meta: { title: '采样详情', icon: '', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] },
  //     }
  //   ]
  // },
  {
    path: '/detection',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '检验检测',
      icon: 'el-icon-document-checked',
      roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page', 'enterprise']
    },
    children: [{
      path: '/monitor/index',
      name: 'Monitor',
      component: () => import('@/views/monitor/index'),
      meta: {
        title: '定量检测',
        icon: '',
        roles: ['admin', 'isDeep_admin', 'isDeep_supervise', 'supervise', '6108', '37']
      },
    },
    {
      path: '/monitor/list',
      name: 'MonitorList',
      component: () => import('@/views/monitor/list'),
      hidden: true,
      meta: { title: '导入记录', icon: '', roles: ["admin", 'isDeep_admin', 'isDeep_supervise', 'supervise', '6108', '37'] }
    },
    {
      path: '/qualitative',
      name: 'Qualitative',
      // component: () => import('@/views/detection/index'),
      component: emptyLayout,
      meta: {
        title: '定性检测',
        icon: '',
        roles: ['admin', 'isDeep_admin', 'isDeep_supervise', 'supervise', '6108', '37']
      },
      children: [
        {
          path: '/detection/index',
          name: 'Detection',
          component: () => import('@/views/detection/index'),
          meta: {
            title: '产品抽检查询',
            icon: '',
            roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page', 'enterprise']
          }
        },
        {
          path: '/detection/colloidalGold',
          name: 'ColloidalGold',
          component: () => import('@/views/detection/colloidalGold'),
          meta: {
            title: '胶体金免疫层析法检测',
            icon: '',
            roles: ['admin', 'supervise', '6108', '37']
          }
        },
        {
          path: '/detection/enzyme',
          name: 'enzyme',
          component: () => import('@/views/detection/enzyme'),
          meta: {
            title: '酶抑制率法检测',
            icon: '',
            roles: ['admin', 'supervise', '6108', '37',]
          }
        },
        {
          path: '/detection/collaurum',
          name: 'Colloidal',
          component: () => import('@/views/collaurum'),
          meta: {
            title: '采样任务',
            icon: '',
            roles: ['admin', 'supervise', '37']
          }
        },
        {
          path: '/detection/collaurum/detail',
          name: 'collaurumDetail',
          component: () => import('@/views/collaurum/detail'),
          hidden: true,
          meta: {
            title: '采样任务详情',
            icon: '',
            roles: ['admin', 'supervise', '37']
          }
        },
        {
          path: '/sampleRecord',
          name: 'SampleRecord',
          component: () => import('@/views/sampleRecord/index'),
          meta: {
            title: '采样样品记录',
            icon: '',
            roles: ['admin', 'supervise', '6108', '37',]
          }
        },
        {
          path: '/sampleRecord/detail',
          name: 'SampleRecordDetail',
          hidden: true,
          component: () => import('@/views/sampleRecord/detail'),
          meta: {
            title: '采样样品记录详情',
            icon: '',
            activeMenu: '/sampleRecord',
            roles: ['admin', 'supervise', '6108', '37']
          }
        },
      ]
    },
    {
      path: '/detection/add',
      name: 'Detection',
      hidden: true,
      component: () => import('@/views/detection/add'),
      meta: {
        title: '新增检测记录',
        icon: '',
        roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page', 'enterprise']
      }
    }, {
      path: '/detection/detail',
      name: 'DetectionDetail',
      hidden: true,
      component: () => import('@/views/detection/detail'),
      meta: {
        title: '定性检测详情',
        icon: '',
        activeMenu: '/detection/index',
        roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page', 'enterprise']
      }
    },
    {
      path: '/monitor/list',
      name: 'MonitorList',
      component: () => import('@/views/monitor/list'),
      hidden: true,
      meta: {
        title: '导入记录',
        icon: '',
        roles: ['isDeep_admin', 'isDeep_supervise', 'supervise', '6108', '37']
      }
    },
    ]
  },
  // 2022/11/2 注释
  // {
  //    path: '/store',
  //   component: Layout,
  //   // 2022/6/10  西安隐藏
  //   hidden: true,
  //   name: 'storeManage',
  //   meta: { icon: 'shopping', roles: ['admin', 'supervise','6108','37','610802','6103-page'] },
  //   children: [{
  //     path: '/store/index',
  //     name: 'Store',
  //     component: () => import('@/views/store/index'),
  //     meta: { title: '农资监管', icon: '', roles: ['admin', 'supervise','6108','37','610802','6103-page'] }
  //   }, {
  //     path: '/store/detail',
  //     name: 'StoreDetail',
  //     hidden: true,
  //     component: () => import('@/views/store/detail'),
  //     meta: { title: '农资监管详情', icon: '', roles: ['admin', 'supervise','6108','37','610802','6103-page'] }
  //   }]
  // },
  // {
  //   path: '/credit',
  //   component: Layout,
  //   meta: { title: '信用管理', icon: 'el-icon-postcard', roles: ['isDeep_admin', 'isDeep_supervise', 'supervise', '6108', '37', '610802', '6103-page'] },
  //   children: [
  //     {
  //       path: '/credit/index',
  //       name: 'Credit',
  //       component: () => import('@/views/credit/index'),
  //       meta: { title: '信用目录', icon: '', roles: ['isDeep_admin', 'isDeep_supervise', 'supervise', '6108', '37', '610802', '6103-page'] }
  //     },
  //     {
  //       path: '/credit-grade-evaluate',
  //       name: 'credit-grade-evaluate',
  //       component: () => import('@/views/credit-grade-evaluate/index.vue'),
  //       meta: { title: '信用等级评价', icon: '', roles: ['isDeep_admin', '610802', '6108', '6103-page'] }
  //     },
  //     {
  //       path: '/credit-grade-evaluatec/detail',
  //       name: '/credit-grade-evaluatec/detail',
  //       hidden: true,
  //       component: () => import('@/views/credit-grade-evaluate/detail.vue'),
  //       meta: { title: '信用等级评价-详情', icon: '', roles: ['isDeep_admin', '610802', "6108", '6103-page'] }
  //     },
  //     {
  //       path: '/dynamic/index',
  //       name: 'DynamicIndex',
  //       component: () => import('@/views/dynamic/index'),
  //       meta: { title: '信用评价', icon: '', roles: ['isDeep_admin', 'isDeep_supervise', 'supervise', '6108', '37', '610802', '6103-page'] }
  //     },
  //     {
  //       path: '/dynamic/detail',
  //       name: 'DynamicDetail',
  //       hidden: true,
  //       component: () => import('@/views/dynamic/detail'),
  //       meta: { title: '信用评价-详情', icon: '', roles: ['isDeep_admin', 'isDeep_supervise', 'supervise', '6108', '37', '610802', '6103-page'] }
  //     },
  //     {
  //       path: '/dynamic/show',
  //       name: 'DynamicShow',
  //       hidden: true,
  //       component: () => import('@/views/dynamic/show'),
  //       meta: { title: '信用评价-查看', icon: '', roles: ['isDeep_admin', 'isDeep_supervise', 'supervise', '6108', '37', '610802', '6103-page'] }
  //     },
  //     {
  //       path: '/redBlack/index',
  //       name: 'RedBlack',
  //       component: () => import('@/views/redBlack/index'),
  //       meta: { title: '红黑名单', icon: '', roles: ['isDeep_admin', 'isDeep_supervise'] }
  //     },
  //   ]
  // },
  {
    path: '/cert',
    component: Layout,
    meta: {
      title: '合格证管理',
      icon: 'skill',
      roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page', 'enterprise']
    },
    children: [{
      path: '/cert/index',
      name: 'Cert',
      component: () => import('@/views/cert/index'),
      meta: {
        title: '合格证信息',
        icon: '',
        roles: ['admin', 'enterprise', 'supervise', '6108', '37', '610802', '6103-page']
      }
    },
    // { // 山东
    //   path: '/dock/index',
    //   name: 'Dock',
    //   hidden: isSD,
    //   component: () => import('@/views/dock/index'),
    //   meta: {
    //     title: '合格证对接数据',
    //     icon: '',
    //     roles: ['admin', 'enterprise', 'supervise', '6108', '37', '610802', '6103-page']
    //   }
    // },
    { // 重庆
      path: '/paper/index',
      name: 'Paper',
      hidden: isCQ,
      component: () => import('@/views/paper/index'),
      meta: {
        title: '国家平台合格证',
        icon: '',
        roles: ['admin', 'enterprise', 'supervise', '6108', '37', '610802', '6103-page']
      }
    },
    {
      path: '/print/index',
      name: 'Print',
      component: () => import('@/views/print/index'),
      meta: {
        title: '合格证打印',
        icon: '',
        roles: ['admin', 'enterprise', 'supervise', '6108', '37', '610802', '6103-page']
      }
    },
    {
      path: '/showPrint/index',
      name: 'ShowPrint',
      hidden: true,
      component: () => import('@/views/showPrint/index'),
      meta: {
        title: '打印详情',
        icon: '',
        roles: ['admin', 'enterprise', 'supervise', '6108', '37', '610802', '6103-page']
      }
    },
    // {
    //   path: '/search/index',
    //   name: 'Search',
    //   component: () => import('@/views/search/index'),
    //   meta: {
    //     title: '合格证查询',
    //     icon: '',
    //     roles: ['admin', 'enterprise', 'supervise', '6108', '37', '610802', '6103-page']
    //   }
    // },
    { // todo: 需要写
      path: '/examine/index',
      name: 'Examine',
      component: () => import('@/views/examine/index'),
      meta: {
        title: '合格证查验',
        icon: '',
        roles: ['isDeep_admin', 'isDeep_enterprise', 'isDeep_supervise']
      }
    },
    ]
  },
  {
    path: '/production',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '追溯管理',
      icon: 'el-icon-folder-opened',
      roles: ['admin', 'enterprise', 'supervise', '6108', '37', '610802', '6103-page']
    },
    children: [{
      path: '/production/index',
      name: 'Production',
      component: () => import('@/views/production/index'),
      meta: {
        title: '生产档案',
        icon: '',
        roles: ['admin', 'enterprise', 'supervise', '6108', '37', '610802', '6103-page']
      }
    }, {
      path: '/production/detail',
      name: 'ProductionDetail',
      hidden: true,
      component: () => import('@/views/production/detail'),
      meta: {
        title: '生产档案详情',
        icon: '',
        activeMenu: '/production/index',
        roles: ['admin', 'enterprise', 'supervise', '6108', '37', '610802', '6103-page']
      }
    },]
  },
  {
    path: '/statistic',
    component: Layout,
    meta: {
      title: '数据统计',
      icon: 'chart',
      roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
    },
    children: [
      // {
      //   path: '/statistic/index',
      //   name: 'Statistic',
      //   component: () => import('@/views/statistic/index'),
      //   meta: { title: '企业数量统计', icon: '', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] }
      // },
      {
        path: '/statistic/index_check_count',
        name: 'CheckCount',
        component: () => import('@/views/statistic/index_check_count'),
        meta: {
          title: '定性检测统计',
          icon: '',
          roles: ['isDeep_admin', 'isDeep_supervise']
        }
      }, {
        path: '/statistic/index_monitor',
        name: 'StatisticMonitor',
        component: () => import('@/views/statistic/index_monitor'),
        meta: {
          title: '监测数据统计',
          icon: '',
          roles: ['isDeep_admin', 'isDeep_supervise']
        }
      }, {
        path: '/statistic/index_print_num',
        name: 'PrintNum',
        component: () => import('@/views/statistic/index_print_num'),
        meta: {
          title: '打印数量统计',
          icon: '',
          roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
        }
      }, {
        path: '/statistic/index_search_num',
        name: 'SearchNum',
        component: () => import('@/views/statistic/index_search_num'),
        meta: {
          title: '查询次数统计',
          icon: '',
          roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
        }
      }, {
        path: '/statistic/index_certificate_record',
        name: 'CertificateRecord',
        component: () => import('@/views/statistic/index_certificate_record'),
        meta: {
          title: '合格证记录统计',
          icon: '',
          roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
        }
      }, {
        path: '/statistic/index_print_record',
        name: 'PrintRecord',
        component: () => import('@/views/statistic/index_print_record'),
        meta: {
          title: '打印记录统计',
          icon: '',
          roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
        }
      },
      // {
      //   path: '/statistic/qualified-usage-rate',
      //   name: 'GridData',
      //   component: () => import('@/views/statistic/qualified-usage-rate'),
      //   meta: { title: '合格证使用地区排行', icon: '', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] }
      // }, 
      {
        path: '/statistic/index_certificate_report',
        name: 'CertificateReport',
        component: () => import('@/views/statistic/index_certificate_report'),
        meta: {
          title: '合格证统计报表',
          icon: '',
          roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
        }
      },
      // {
      //   path: '/statistic/index_certificate_industry',
      //   name: 'CertificateIndustry',
      //   component: () => import('@/views/statistic/index_certificate_industry'),
      //   meta: { title: '合格证行业统计报表', icon: '', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] }
      // }, 
      // {
      //   path: '/statistic/index_certificate_industry?industry=种植业',
      //   name: 'CertificateIndustry',
      //   component: () => import('@/views/statistic/index_certificate_industry'),
      //   meta: { title: '种植业合格证统计', icon: '', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] }
      // },
      // {
      //   path: '/statistic/index_certificate_industry?industry=畜牧业',
      //   name: 'CertificateIndustry',
      //   component: () => import('@/views/statistic/index_certificate_industry'),
      //   meta: { title: '畜牧业合格证统计', icon: '', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] }
      // },
      // {
      //   // yh 2022-4-28 add
      //   path: '/statistic/index_certificate_regulator',
      //   name: 'CertificateIndustry',
      //   component: () => import('@/views/statistic/index_certificate_regulator'),
      //   meta: { title: '网格化监管数据统计', icon: '', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] }
      // }, 
      // {
      //   path: '/statistic/index_grid_data',
      //   name: 'GridData',
      //   component: () => import('@/views/statistic/index_grid_data'),
      //   meta: { title: '网格数据统计', icon: '', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] }
      // }, 
      {
        path: '/statistic/index_print_num_by_ent',
        name: 'PrintNumByEnt',
        component: () => import('@/views/statistic/index_print_num_by_ent'),
        meta: {
          title: '企业打印数量统计',
          icon: '',
          roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
        }
      }, {
        path: '/statistic/index_supervise_people',
        name: 'SupervisePeople',
        component: () => import('@/views/statistic/index_supervise_people'),
        meta: {
          title: '监管人员工作统计',
          icon: '',
          roles: ['isDeep_admin', 'isDeep_supervise']
        }
      }, {
        path: '/statistic/inside_reg_worker_patrol',
        name: 'RegWorkerPatrol',
        hidden: true,
        component: () => import('@/views/statistic/inside_reg_worker_patrol'),
        meta: {
          title: '巡查记录统计',
          icon: '',
          roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
        }
      }, { // 山东监管通
        path: '/statistic/inside_reg_worker_sign',
        name: 'RegWorkerSign',
        hidden: isJGT,
        component: () => import('@/views/statistic/inside_reg_worker_sign'),
        meta: {
          title: '签到信息统计',
          icon: '',
          roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
        }
      }, { // 山东监管通
        path: '/statistic/inside_reg_worker_dynamic',
        name: 'RegWorkerDynamic',
        hidden: isJGT,
        component: () => import('@/views/statistic/inside_reg_worker_dynamic'),
        meta: {
          title: '工作动态统计',
          icon: '',
          roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
        }
      }
    ]
  },
  // {
  //   path: '/auth',
  //   component: Layout,
  //   alwaysShow: true,
  //   meta: { title: '认证管理', icon: 'international', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] },
  //   children: [
  //     {
  //       path: '/auth/index',
  //       name: 'Auth',
  //       hidden: !isCQ && !isSX,
  //       component: () => import('@/views/auth/index'),
  //       meta: { title: '资质认证', icon: '', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] }
  //     },
  //     {
  //       path: '/auth/detail',
  //       name: 'AuthDetail',
  //       hidden: true,
  //       component: () => import('@/views/auth/detail'),
  //       meta: { title: '资质认证详情', icon: '', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] }
  //     }
  //   ]
  // },
  // {
  //   path: '/news',
  //   component: Layout,
  //   redirect: '/news/index',
  //   meta: { title: '工作动态', icon: 'el-icon-news', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] },
  //   children: [
  //     {
  //       path: '/article/index',
  //       name: 'Article',
  //       component: () => import('@/views/article/index'),
  //       meta: { title: '资讯管理', icon: '', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] }
  //     },
  //     {
  //       path: '/channel/index',
  //       name: 'Channel',
  //       component: () => import('@/views/channel/index'),
  //       meta: {
  //         title: '栏目管理',
  //         icon: '',
  //         roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
  //       }
  //     }, {
  //       path: '/article/detail',
  //       name: 'ArticleDetail',
  //       hidden: true,
  //       component: () => import('@/views/article/detail'),
  //       meta: { title: '工作动态-详情', icon: '', roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page'] }
  //     }
  //   ]
  // },
  {
    path: '/law',
    component: Layout,
    // 2022/6/10  西安隐藏
    hidden: true,
    meta: {
      title: '监督执法',
      icon: 'el-icon-s-check',
      roles: ['isDeep_admin', 'isDeep_supervise']
    },
    children: [{ // todo: 暂无
      path: '/law/index',
      name: 'Law',
      component: () => import('@/views/law/index'),
      meta: {
        title: '监督执法',
        icon: '',
        roles: ['isDeep_admin', 'isDeep_supervise']
      }
    }]
  },



  {
    path: '/system',
    component: Layout,
    redirect: '/user/index',
    meta: {
      title: '系统管理',
      icon: 'setting',
      roles: ['admin', 'device', 'JG-3701-001']
    },
    children: [{
      path: '/user/index',
      name: 'User',
      component: () => import('@/views/user/index'),
      meta: {
        title: '用户信息',
        icon: '',
        roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
      }
    },
    {
      path: '/prod/index',
      name: 'prod-index',
      component: () => import('@/views/prod/index'),
      meta: {
        title: '产品种类',
        icon: '',
        roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
      }
    },
    // {
    //   path: '/bindCode/index',
    //   name: 'BindCode',
    //   component: () => import('@/views/bindCode/index'),
    //   meta: {
    //     title: '绑定标签',
    //     icon: '',
    //     roles: ['admin']
    //   }
    // }, 
    // {
    //   path: '/bindCode/list',
    //   name: 'BindCodeList',
    //   hidden: true,
    //   component: () => import('@/views/bindCode/list'),
    //   meta: {
    //     title: '绑码标签明细',
    //     icon: '',
    //     roles: ['admin']
    //   }
    // },
    // {
    //   path: '/assignCode/index',
    //   name: 'AssignCode',
    //   component: () => import('@/views/assignCode/index'),
    //   meta: {
    //     title: '分配标签',
    //     icon: '',
    //     roles: ['admin']
    //   }
    // }, {
    //   path: '/assignCode/index-list',
    //   name: 'AssignCodeIndexList',
    //   hidden: true,
    //   component: () => import('@/views/assignCode/index-list'),
    //   meta: {
    //     title: '标签分配明细',
    //     icon: '',
    //     roles: ['admin']
    //   }
    // }, {
    //   path: '/assignCode/list',
    //   name: 'AssignCodeList',
    //   hidden: true,
    //   component: () => import('@/views/assignCode/list'),
    //   meta: {
    //     title: '标签激活记录',
    //     icon: '',
    //     roles: ['admin']
    //   }
    // }, {
    //   path: '/assignCode/list-detail',
    //   name: 'AssignCodeListDetail',
    //   hidden: true,
    //   component: () => import('@/views/assignCode/list-detail'),
    //   meta: {
    //     title: '标签绑定记录',
    //     icon: '',
    //     roles: ['admin']
    //   }
    // },
    {
      path: '/instrument/index',
      name: 'Instrument',
      component: () => import('@/views/instrument/index'),
      meta: {
        title: '检测设备管理',
        icon: '',
        roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page', 'device']
      }
    },
    {
      path: '/instrument/list',
      name: 'InstrumentList',
      component: () => import('@/views/instrument/list'),
      hidden: true,
      meta: {
        title: '访问资源管理',
        icon: '',
        roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page', 'device']
      }
    },
    {
      path: '/instrument/user',
      name: 'InstrumentUser',
      hidden: true,
      component: () => import('@/views/instrument/user'),
      meta: {
        title: '检测设备账号',
        icon: '',
        roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page', 'device']
      }
    },
      // {
      //   path: '/area/index',
      //   name: 'Area',
      //   component: () => import('@/views/area/index'),
      //   meta: {
      //     title: '行政地区管理',
      //     icon: '',
      //     roles: ['admin']
      //   }
      // },
      // {
      //   path: '/patrol/index',
      //   name: 'Patrol',
      //   hidden: isSX,
      //   component: () => import('@/views/patrol/index'),
      //   meta: {
      //     title: '巡查方案配置',
      //     icon: '',
      //     roles: ['admin', 'supervise', '6108', '37', '610802', '6103-page']
      //   }
      // },
    ]
  },
]
