<template>
  <el-form-item :label="label">
    <el-date-picker
      v-model="time"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      type="daterange"
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :picker-options="pickerOptions"
    ></el-date-picker>
  </el-form-item>
</template>
<script>
import { getMonth } from "../../utils/formatDate";
export default {
  name: "date-picker",
  props: {
    // 默认时间区域
    //默认一个月
    typeMonth: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "时间",
    },
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              console.log(picker);
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      time: "",
    };
  },
  created() {
    if (this.typeMonth) this.time = getMonth();
  },
  watch: {
    time(arr) {
      this.$emit("getDatePickerValue", arr);
    },
  },
  mounted() {},
  methods: {},
};
</script>
