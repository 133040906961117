<template>
  <div class="changePassword">
    <el-dialog
      :title="title"
      :visible.sync="showChangePwd"
      append-to-body
      @close="closeDialog"
      width="600px"
      class="changePwd"
      :close-on-click-modal="closeOnClickModal"
      :show-close="showClose"
    >
      <el-form status-icon :rules="rules" ref="changePwdForm">
        <div class="block">
          <el-form-item label="旧密码" prop="oldPwd" label-width="200px">
            <el-input
              v-model="changePwdForm.oldPwd"
              clearable
              type="password"
              placeholder="请填写旧密码"
            />
          </el-form-item>
        </div>
        <div class="block">
          <el-form-item label="新密码" prop="newPwd" label-width="200px">
            <el-input
              v-model="changePwdForm.newPwd"
              clearable
              type="password"
              placeholder="请填写新密码"
            />
          </el-form-item>
        </div>
        <div class="block">
          <el-form-item label="确认新密码" prop="repeatPwd" label-width="200px">
            <el-input
              v-model="changePwdForm.repeatPwd"
              clearable
              type="password"
              placeholder="请再输一遍新密码"
            />
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer submitBox">
        <el-button @click="clearChangePwdForm">清 空</el-button>
        <el-button type="primary" @click="submitChangePwd">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { changePwd } from "@/api/user";
import { getUrlQuery } from "@/utils/storage";
import { removeIndustry } from "@/utils/cookie";

export default {
  // props: ["show"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    closeOnClickModal: {
      type: Boolean,
      default: true,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    hint: {
      type: String,
      default: "修改密码",
    },
  },

  data() {
    let validatorOldPwd = (rule, value, callback) => {
      if (this.changePwdForm.oldPwd == "") {
        callback(new Error("旧密码不能为空"));
      }
    };
    let validatorNewPwd = (rule, value, callback) => {
      var reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,18}$/;
      if (!reg.test(this.changePwdForm.newPwd)) {
        callback(
          new Error("密码长度为8-18位字符，且必须同时包含字母、数字和特殊字符)！")
        );
      } else {
        callback();
      }
    };
    let validatorRepeatPwd = (rule, value, callback) => {
      let bol = this.changePwdForm.newPwd === this.changePwdForm.repeatPwd;
      if (!bol) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      title: "修改密码",
      showChangePwd: false,
      changePwdForm: {
        oldPwd: "",
        newPwd: "",
        repeatPwd: "",
      },
      rules: {
        oldPwd: [{ validator: validatorOldPwd, trigger: "blur" }],
        newPwd: [{ validator: validatorNewPwd, trigger: "blur" }],
        repeatPwd: [{ validator: validatorRepeatPwd, trigger: "blur" }],
      },
    };
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {
    // show(newValue) {
    //   this.showChangePwd = newValue;
    // },
    show: {
      handler: function (newValue) {
        this.showChangePwd = newValue;
      },
      immediate: true,
    },
    hint: {
      handler: function (newValue) {
        this.title = newValue;
      },
      immediate: true,
    },
  },
  methods: {
    closeDialog(bool) {
      this.$emit("closeShow", bool);
    },
    clearChangePwdForm() {
      // 重置密码表单
      this.changePwdForm.oldPwd = "";
      this.changePwdForm.newPwd = "";
      this.changePwdForm.repeatPwd = "";
    },
    submitChangePwd() {
      const { oldPwd, newPwd, repeatPwd, userId } = this.changePwdForm;
      if (!oldPwd) return this.$message.error("旧密码必填！");
      var reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,18}$/;
      if (!reg.test(newPwd)) return this.$message.error("请按照规定填写新密码！");
      // if (!oldPwd || !newPwd || !repeatPwd) {
      //   this.$message.error("打*项为必填项！");
      //   return;
      // }
      if (newPwd !== repeatPwd) {
        this.$message.error("两次输入的新密码不一致！");
        return;
      }
      const confirmText = `是否确认修改密码?`;
      this.$confirm(confirmText, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(() => {
          changePwd({
            oldPwd,
            newPwd,
          })
            .then(async (res) => {
              this.showChangePwd = false;
              if (res.state === "Y") {
                this.closeDialog();
                // 退出登录
                this.$message({
                  type: "success",
                  message: "密码修改成功!",
                  onClose: () => {
                    this.closeDialog(true);
                  },
                });
                let source = getUrlQuery() || "";
                await this.$store.dispatch("user/logout").then(() => {
                  this.$router.push(`/login`);
                  // 退出时删除本地保存的用户行业数据
                  removeIndustry();
                  this.$router.push({
                    path: "/login",
                    query: {
                      source,
                    },
                  });
                });
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err);
            });
        })
        .catch((cancel) => {
          console.log("取消删除！");
        });
    },
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.changePassword {
  position: relative;
}
</style>
