exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".tags-view-wrapper .tags-view-item .el-icon-close{width:16px;height:16px;vertical-align:2px;border-radius:50%;text-align:center;transition:all .3s cubic-bezier(0.645, 0.045, 0.355, 1);transform-origin:100% 50%}.tags-view-wrapper .tags-view-item .el-icon-close:before{transform:scale(0.6);display:inline-block;vertical-align:-3px}.tags-view-wrapper .tags-view-item .el-icon-close:hover{background-color:#b4bccc;color:#fff}", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409eff",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "210px"
};