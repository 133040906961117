var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    { attrs: { label: _vm.label } },
    [
      _c("el-date-picker", {
        attrs: {
          format: "yyyy-MM-dd",
          "value-format": "yyyy-MM-dd",
          type: "daterange",
          "unlink-panels": "",
          "range-separator": "至",
          "start-placeholder": "开始日期",
          "end-placeholder": "结束日期",
          "picker-options": _vm.pickerOptions
        },
        model: {
          value: _vm.time,
          callback: function($$v) {
            _vm.time = $$v
          },
          expression: "time"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }