import {
  toLogin,
  rasToLogin
} from '@/api/login'
import {
  getToken,
  setToken,
  setUserName,
  setUserType,
  removeToken,
  getJGT,
} from '@/utils/cookie'
import {
  setStorage,
  getStorage,
  setAreaCode,
} from '@/utils/storage'
import router, {
  resetRouter
} from '@/router'
// import { process.env.VUE_APP_BASE_printAreaCode } from '@/utils/baseUrl'
const state = {
  token: getToken(),
  name: '',
  userType: '',
  roles: [],
  areaCode: '',
}
// ras 加密使用
import { JSEncrypt } from "jsencrypt";//工具
import { rasWhiteList } from '@/utils/ras_confing';//白名单
// import { ras } from "@/utils/baseUrl";

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_USERTYPE: (state, userType) => {
    state.userType = userType
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  }
}

const actions = {
  // user login
  getToken({
    commit
  }, userInfo) {
    const {
      loginName,
      password,
    } = userInfo;
    //如果 process.env.VUE_APP_BASE_printAreaCode 编码存在 ras 白名单中 使用登录加密 
    // if (rasWhiteList.includes(process.env.VUE_APP_BASE_printAreaCode)) {
    console.log(Boolean(process.env.VUE_APP_BASE_ras))
    if (Boolean(process.env.VUE_APP_BASE_ras)) {
      // 新建JESencrypt对象
      const encryptor = new JSEncrypt();
      //   设置公钥
      encryptor.setPublicKey("MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDGF0t0+dpdzxOADDAlNpekY6286At0qbx4G0VKzpVuDcUGYsffh1xtsX6CWm8jMCddXW3yl1SBI5hb2KMOoRMIQdji66A/919x0jMyNZFrJPkevTTFSQq4xaDYRAdUpKUfDVJKW+0uLIPW6V/ZvRggUqsC4RrVnAf28cevlEXLhwIDAQAB"); // publicKey为公钥
      // return encryptor.encrypt(pwd);
      let data = JSON.stringify({ loginName, password, type: 0 });
      let params = encryptor.encrypt(data);
      return new Promise((resolve, reject) => {
        rasToLogin({ params }).then(response => {
          if (response.state === 'Y') {
            const data = response.data
            if (getJGT() && (data.userType !== 0 && data.userType !== 2 && data.userType !== 5)) {
              reject('请使用监管账号！')
              return
            }
            commit('SET_TOKEN', data.token)
            commit('SET_NAME', data.titleName)
            commit('SET_USERTYPE', data.userType)
            setUserName(data.titleName)
            setToken(data.token)
            setUserType(data.userType)
            setStorage(data)
            setAreaCode(data.areaCode)
            resolve(data)
          } else {
            reject(response.msg)
          }
        }).catch(error => {
          reject(error)
        })
      })
    } else {
      return new Promise((resolve, reject) => {
        toLogin({
          loginName: loginName.trim(),
          password: password,
          type: 0
        }).then(response => {
          if (response.state === 'Y') {
            const data = response.data
            if (getJGT() && (data.userType !== 0 && data.userType !== 2 && data.userType !== 5)) {
              reject('请使用监管账号！')
              return
            }
            commit('SET_TOKEN', data.token)
            commit('SET_NAME', data.titleName)
            commit('SET_USERTYPE', data.userType)
            setUserName(data.titleName)
            setToken(data.token)
            setUserType(data.userType)
            setStorage(data)
            setAreaCode(data.areaCode)
            resolve(data)
          } else {
            reject(response.msg)
          }
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  // get user info
  getInfo({
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {
      const info = getStorage();
      if (JSON.stringify(info) === '{}') {
        reject('getInfo: roles must be a non-null array!')
      }
      const isGuest = info.isGuest;
      const userType = info.userType;
      let data = {
        roles: ['admin']
      };
      /**
       * 用户类型-游客 -1;
       * 用户类型-管理员 0;
       * 用户类型-企业用户-普通用户 1;
       * 用户类型-地区监管用户 2;
       * 用户类型-企业用户-操作员-直接通过身份证登录 3; - 小程序端
       * 用户类型-企业用户-微信 4; - 小程序端
       * 用户类型-监管人员 5;
       * 用户类型（非业务）-设备管理 101;
       */
      if (info.areaCode.indexOf('6101') > -1) { // 西安
        if (userType === 1) { // 企业
          data = {
            roles: ['isDeep_enterprise', 'enterprise']
          };
          commit('SET_ROLES', data.roles);
        } else if (userType === 0) { // 管理员
          data = {
            roles: ['isDeep_admin', 'admin']
          };
          commit('SET_ROLES', data.roles);
        } else if (userType === 2) { // 地区监管
          data = {
            roles: ['isDeep_supervise', 'supervise']
          };
          commit('SET_ROLES', data.roles);
        } else if (userType === 5) { // 监管人员
          data = {
            roles: ['isDeep_supervise', 'supervise']
          };
          commit('SET_ROLES', data.roles);
        } else if (userType === -1) { // 游客
          data = {
            roles: ['guest']
          };
          commit('SET_ROLES', data.roles);
        } else if (userType === 101) { // 设备
          data = {
            roles: ['device']
          };
          commit('SET_ROLES', data.roles);
        } else {
          data = {
            roles: ['guest']
          };
          commit('SET_ROLES', data.roles);
        }
      }

      else {
        if (userType === 1) { // 企业
          if (info.areaCodeCity == '6103') {//宝鸡
            data = {
              roles: ['6103-lse', 'enterprise']
            };
          } else {
            data = {
              roles: ['enterprise']
            };
          }
          commit('SET_ROLES', data.roles);
        } else if (userType === 0) { // 管理员 ------
          data = {
            roles: ['admin']
          };
          commit('SET_ROLES', data.roles);
        } else if (userType === 2) { // 地区监管 ------
          if (info.areaCodeCity == '6108') {
            data = {
              roles: ['6108']
            };
          } else if (info.areaCodeDistrict == '610802') {
            data = {
              roles: ['610802']
            };
          } else if (info.areaCodeCity == '6103') {//宝鸡
            data = {
              roles: ['6103-page', '6103-lse']
            };
          } else if (process.env.VUE_APP_BASE_printAreaCode === '37'&&process.env.VUE_APP_BASE_ENV!='jnly') { // 山东
            data = {
              roles: ['37']
            }
          } else if(process.env.VUE_APP_BASE_ENV==='jnly'){
            if(info.userId==='1136703921788592128'){
              data = {
                roles: ['37','JG-3701-001']
              };
            }
          }
          else {
            data = {
              roles: ['supervise']
            };
          }
          commit('SET_ROLES', data.roles);
        } else if (userType === 5) { // 监管人员 ------
          data = {
            roles: ['supervise']
          };
          if (process.env.VUE_APP_BASE_printAreaCode === '37') { // 山东
            data = {
              roles: ['37']
            }
          }
          commit('SET_ROLES', data.roles);
        } else if (userType === -1) { // 游客 ------
          data = {
            roles: ['guest']
          }
          commit('SET_ROLES', data.roles);
        } else if (userType === 101) { // 设备 ------
          data = {
            roles: ['device']
          }
          commit('SET_ROLES', data.roles);
        } else {
          data = {
            roles: ['guest']
          }
          commit('SET_ROLES', data.roles);
        }
      }
      // if(info.areaCode === '6103'){
      //   data = {
      //     roles: ['admin',]
      //   };
      //   commit('SET_ROLES', data.roles);
      // }
      resolve(data)
    })
  },

  // user logout
  logout({
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resetRouter()
      resolve()
    })
  },

  // remove token
  resetToken({
    commit
  }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resetRouter()
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
