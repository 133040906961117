<template>
  <div class="app-page">
    <div class="page-title">
      <h1>{{ pageTitle }}</h1>
    </div>
    <div :class="classObj" class="app-wrapper">
      <div
        v-if="device === 'mobile' && sidebar.opened"
        class="drawer-bg"
        @click="handleClickOutside"
      />
      <sidebar class="sidebar-container" />
      <div class="main-container">
        <div :class="{ 'fixed-header': fixedHeader }">
          <navbar />
          <tags-view />
        </div>
        <app-main />
      </div>
    </div>
  </div>
</template>

<script>
import { AppMain, Navbar, Sidebar, TagsView } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
// import { printAreaCode } from "@/utils/baseUrl";
import { getStorage } from "@/utils/storage";
import { getJGT } from "@/utils/cookie";
import logoBg_sx_trace_supervise from "@/views/login/login3.png";
import logoBg from "@/views/login/login-bg.png";

export default {
  name: "Layout",
  components: {
    Navbar,
    Sidebar,
    AppMain,
    TagsView,
  },
  data() {
    return {
      pageTitle: "",
    };
  },
  created() {
    let userInfo = getStorage() || {};
    let userType = userInfo.userType;
    if (process.env.VUE_APP_BASE_printAreaCode === "61") {
      // 陕西
      let source = this.$route.query.source || "";
      this.source = source;
      if (source === "1") {
        // 易追溯
        this.pageTitle = "榆林市生产经营主体登录系统";
      } else if (source === "2") {
        // 易监管
        this.pageTitle = "榆林市监管人员登录系统";
      } else if (source === "3") {
        // 办公人员
        this.pageTitle = "榆林市办公人员登录系统";
      } else if (source === "4") {
        // 西安
        this.pageTitle = "西安市农产品质量安全智慧监管平台";
      } else if (source === "5") {
        // 西安
        this.pageTitle = "西安市农产品质量安全智慧企业平台";
      } else if (source === "6") {
        // 宝鸡
        this.pageTitle = "宝鸡市农产品质量安全智慧监管平台";
      } else if (source === "7") {
        window.alert('6')
        // 宝鸡
        this.pageTitle = "宝鸡市农产品质量安全智慧企业平台";
      } else {
        // this.pageTitle = this.$store.state.settings.title;
        if (userType === 0 || userType === 2 || userType === 5) {
          this.pageTitle = "农产品质量安全智慧监管平台";
        } else {
          this.pageTitle = "农产品质量安全智慧企业平台";
        }
      }
    } else if (process.env.VUE_APP_BASE_printAreaCode === "37") {
      // 山东
      if (getJGT()) {
        this.pageTitle = "山东农产品质量安全监管系统";
      } else {
        this.pageTitle = `${userInfo.areaName || ""}${this.$store.state.settings.title}`;
      }
    } else if (process.env.VUE_APP_BASE_printAreaCode === "13") {
      // 河北
      if (window.location.host.indexOf("czhgz") > -1) {
        // 沧州
        this.pageTitle = "沧州市农产品质量安全追溯（合格证）监管系统";
      } else {
        this.pageTitle = "食用农产品易监管易追溯平台";
      }
    } else {
      this.pageTitle = this.$store.state.settings.title;
    }
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    device() {
      return this.$store.state.app.device;
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", { withoutAnimation: false });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.app-page {
  display: flex;
  flex-direction: column;
}

.page-title {
  width: 100%;
  min-width: 1200px;
  flex: 0 0 60px;
  height: 60px;
  line-height: 60px;
  background: $color-main;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  h1 {
    font-size: 23px;
    color: #fff;
    padding-left: 30px;
    letter-spacing: 2px;
  }
}

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
  flex: 1;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 60px;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
</style>
